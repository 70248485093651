<template>

    <AppHead title="Login" />

    <div class="d-flex flex-center flex-column flex-lg-row-fluid">

        <div class="w-lg-500px p-10">

            <form @submit.prevent="submit" class="form w-100" autocomplete="off">

                <div class="text-center mb-8">

                    <h1 class="text-gray-900 fw-bolder mb-3">Sign In</h1>

                    <div class="text-gray-500 fw-semibold fs-6">To start the day with Virtupply.</div>

                </div>

                <div class="row g-3 mb-9">

                    <div class="col-md-6">

                        <LoginOption href="/login/google/redirect" :src="'/assets/media/svg/brand-logos/google-icon.svg'">Sign in with Google</LoginOption>

                    </div>

                    <div class="col-md-6">

                        <LoginOption href="/login/microsoft/redirect" :src="'/assets/media/svg/brand-logos/microsoft.svg'">Sign in with Microsoft</LoginOption>

                    </div>

                    <div v-if="page.props.errors.provider" class="col-md-12 text-center">
                        <div v-text="page.props.errors.provider" class="text-danger small mt-1"/>
                    </div>

                </div>

                <!--begin::Separator-->
                <div class="separator separator-content my-14">
                    <span class="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
                </div>
                <!--end::Separator-->

                <!--begin::Input group-->
                <div class="mb-8">

                    <!--begin::Email-->
                    <input
                        v-model="form.email"
                        class="form-control bg-transparent"
                        type="text"
                        name="email"
                        placeholder="Email"
                        required
                    />
                    <!--end::Email-->

                    <div v-if="form.errors.email" v-text="form.errors.email" class="text-danger small mt-1"/>

                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="mb-3">

                    <!--begin::Password-->
                    <input
                        v-model="form.password"
                        class="form-control bg-transparent"
                        type="password"
                        name="password"
                        placeholder="Password"
                        required
                    />
                    <!--end::Password-->

                </div>
                <!--end::Input group-->

                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-wrap justify-content-end gap-3 fs-base fw-semibold mb-8">

                    <!--begin::Link-->
                    <Link href="/password/reset" class="link-primary">Forgot Password?</Link>
                    <!--end::Link-->

                </div>
                <!--end::Wrapper-->

                <!--begin::Submit button-->
                <div class="d-grid mb-10">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="form.processing"
                        :data-kt-indicator="form.processing ? 'on' : 'off'"
                    >

                        <!--begin::Indicator label-->
                        <span class="indicator-label">Sign In</span>
                        <!--end::Indicator label-->

                        <!--begin::Indicator progress-->
                        <span class="indicator-progress">
                            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        <!--end::Indicator progress-->

                    </button>
                </div>
                <!--end::Submit button-->

                <!--begin::Sign up-->
                <div class="text-gray-500 text-center fw-semibold fs-6">
                    Not a Account yet? <Link href="/sign-up" class="link-primary">Sign up</Link>
                </div>
                <!--end::Sign up-->

            </form>

        </div>

    </div>

</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3'
import LoginOption from "@/Shares/Auth/Login/LoginOption.vue";

// Define variables
const page = usePage();

// Define form
let form = useForm({
    email: '',
    password: '',
});

let submit = () => {
    form.post('/login');
};

</script>
